import React from "react";

const TermsPage = () => {
    return (
      <html lang="en">
        <head>
          <meta charSet="UTF-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <title>Docs | Code BGP</title>
          <meta http-equiv="refresh" content="0; URL=https://www.thousandeyes.com/blog/cisco-announces-acquisition-of-codebgp" />
        </head>
  
        <body>
        </body>
      </html>
    );
  };

export default TermsPage
